import React from 'react';
import { Switch, Route } from 'react-router-dom';

/** Páginas */
import Main from './pages/Main';
import QuemSomos from './pages/QuemSomos';
import Parceiros from './pages/Parceiros';
import ServicosPrestados from './pages/ServicosPrestados';
import Empreendimentos from './pages/Empreendimentos';
import DetalhesEmpreendimento from './pages/DetalhesEmpreendimento';
// import Painel from './pages/Painel';
import Contato from './pages/Contato';
import NotFound from './pages/NotFound';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      {/* <Redirect from="/" to="/quem-somos" exact /> */}
      <Route path="/quem-somos" exact component={QuemSomos} />
      <Route path="/principais-clientes" exact component={Parceiros} />
      <Route path="/produtos-e-servicos" exact component={ServicosPrestados} />
      <Route path="/portfolio-projetos" exact component={Empreendimentos} />
      <Route path="/portfolio-projetos/detalhes/:id" component={DetalhesEmpreendimento} />
      {/* <Route path="/painel" component={Painel} /> */}
      <Route path="/contato" component={Contato} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
